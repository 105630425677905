@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --theme-text: theme(colors.white);
  --theme-hover: theme(colors.primary);
  --theme-bg: theme(colors.dark);
}

.light-bg {
  --theme-text: theme(colors.dark);
  --theme-hover: theme(colors.white);
  --theme-bg: theme(colors.primary);
}